import React, {useCallback, useEffect, useRef, useState} from 'react'
import {Avatar, Box, Link, Menu, MenuItem, Paper} from '@material-ui/core'
import UserAvatarCircleIcon from '@atlaskit/icon/glyph/user-avatar-circle'
import MoreVerticalIcon from '@atlaskit/icon/glyph/more-vertical'
import CommentIcon from '@atlaskit/icon/glyph/comment'
import {
    addOrRemoveReaction,
    generateReactionData,
    getMemberById,
    isReactionRemoved,
    removeComment,
    updateComment
} from '../../modules/Persistence'
import {useStyles} from './CommentStyle'
import Replies from '../replies/Replies'
import CommentEditor from '../commentEditor/CommentEditor'
import Reactions from '../reactions/Reactions'
import {CommentProps} from '../../types/CommentProps'
import EmojiAddIcon from '@atlaskit/icon/glyph/emoji-add'
import CommentData from '../../types/CommentData'
import {TrackActionEvent} from 'trello-shared-resources/dist'
import Picker, {IEmojiData} from 'emoji-picker-react'
import Member from '../../types/Member'
import {resizeIframe, showEmojiPickerAbove, sizeToContent} from '../../modules/UIUtils'
import CommentMarkdownContent from './CommentMarkdownContent'
import {processReactions} from '../../modules/CommentService'
import {addNeededWarnings} from '../../modules/TextUtils'

const Comment = (props: CommentProps) => {

    const classes = useStyles()

    const defaultUserAvatar = <Box className={classes.defaultAvatar}><UserAvatarCircleIcon label="user name"
                                                                                           size="medium"/></Box>
    const trelloIframeContext = props.licenseDetails.trelloIframeContext

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [replyClicked, setReplyClicked] = useState<boolean>(false)
    const [editingComment, setEditingComment] = useState<boolean>(false)
    const [userAvatar, setUserAvatar] = useState<any>(defaultUserAvatar)
    const [emojiPickerOpened, setEmojiPickerOpened] = useState<boolean>(false)
    const commentEditorRef = useRef<any>(null)
    const emojiLinkRef = useRef<any>(null)
    const emojiWrapperRef = useRef<HTMLDivElement | null>(null)


    const currentMemberId = trelloIframeContext?.getContext()?.member

    const setAvatarByMember = useCallback((member: Member) => {
        if (member && member.avatarUrl)
            setUserAvatar(<Avatar src={`${member.avatarUrl}/30.png`} alt={member.fullName}
                                  className={classes.avatar}/>)
        else if (member && member.initials)
            setUserAvatar(<Avatar className={classes.avatar}
                                  alt={`${member.fullName}`}>{member.initials}</Avatar>)
    }, [classes.avatar])

    const expandRepliesForThreadedComments = useCallback((commentId: string | undefined) => {
        const isThreadedComment = props.comment.threadComments?.some((threadComment) => threadComment.id === commentId)
        if (isThreadedComment && !replyClicked) {
            setReplyClicked(true)
        }
    }, [props.comment.threadComments, replyClicked])


    useEffect(() => {
        if (props.comment.memberId) {
            const boardAndOrganizationMember = props.boardAndOrganizationMembers.find(boardAndOrganizationMember => boardAndOrganizationMember.id === props.comment.memberId)
            if (boardAndOrganizationMember) {
                setAvatarByMember(boardAndOrganizationMember)
            } else {
                getMemberById(props.licenseDetails, props.comment.memberId).then(member => setAvatarByMember(member))
            }
            expandRepliesForThreadedComments(props.commentIdToScroll)

        }
    }, [props.comment, props.licenseDetails, classes.avatar, props.boardAndOrganizationMembers, setAvatarByMember, props.commentIdToScroll, expandRepliesForThreadedComments])

    const handleOpenMenu = (event: any) => {
        event.preventDefault()
        setAnchorEl(event.currentTarget)
    }

    const handleUpdateComment = async (newComment: string, mentionedMembers: Array<string>) => {
        if (props.comment.id && props.comment.memberId === currentMemberId) {
            await updateComment({
                trelloContext: trelloIframeContext,
                commentContent: newComment,
                commentId: props.comment.id,
                mentionedMembers: mentionedMembers,
                currentComments: props.comments,
                boardAndOrganizationMembers: props.boardAndOrganizationMembers,
                parentCommentId: props.comment.parentCommentId
            })
            props.getComments()
        }
        setEditingComment(false)
    }

    const handleEditComment = () => {
        setEditingComment(true)
        handleCloseMenu()
        setTimeout(() => {
            if (commentEditorRef) {
                commentEditorRef.current.focus()
                const [commentToDisplay] = addNeededWarnings(props.comment.commentContent, props.boardAndOrganizationMembers)
                commentEditorRef.current.setSelectionRange(commentToDisplay.length, commentToDisplay.length)
            }
        }, 200)
    }

    /**
     * Remove content from the root comment to look like erased
     * @param commentId comment ID to be removed
     */
    const removeLocallyRootCommentWithThreadedComments = (commentId: string) => {
        props.comment.commentContent = 'This comment was deleted'
        props.comment.commentDisplayContent = 'This comment was deleted'
        props.comment.idRemovedComment = commentId
        props.comment.id = undefined
        props.comment.memberId = undefined
        props.comment.member = undefined
        setUserAvatar(defaultUserAvatar)
        return props.comments.map((comment: CommentData) => comment.id === undefined && comment.idRemovedComment === commentId ? props.comment : comment) as CommentData[]
    }

    /**
     * Remove threaded comment locally
     * @param parentCommentId parent comment where the comment will be removed
     * @param commentId comment ID to be removed
     */
    const removeLocallyThreadedComment = (parentCommentId: string | undefined, commentId: string) => {
        const parentComment = props.comments.find(comment => comment.id === parentCommentId)
        if (parentComment) {
            parentComment.threadComments = parentComment.threadComments?.filter(threadedComment => threadedComment.id !== commentId)
            return props.comments.map((comment: CommentData) => comment.id === parentCommentId ? parentComment : comment) as CommentData[]
        }
        return props.comments
    }

    const handleRemoveComment = async () => {
        handleCloseMenu()
        const commentId = props.comment.id
        if (commentId && props.comment.memberId === currentMemberId) {
            let comments: CommentData[]
            const parentCommentId = props.comment.parentCommentId
            if (props.comment.parentCommentId) {
                comments = removeLocallyThreadedComment(parentCommentId, commentId)
            } else if (!props.comment.threadComments || props.comment.threadComments.length === 0) {
                comments = props.comments.filter(comment => comment.id !== commentId)
            } else {
                comments = removeLocallyRootCommentWithThreadedComments(commentId)
            }
            props.setComments(comments)
            await removeComment(trelloIframeContext, commentId)
            const trelloContextInfo = trelloIframeContext.getContext()
            TrackActionEvent('Comment', trelloContextInfo, {
                board_id: trelloContextInfo.board,
                card_id: trelloContextInfo.card,
                action: 'remove',
                threaded_comment: parentCommentId !== null && parentCommentId !== undefined && parentCommentId !== ''
            })
            props.getComments()
        }
    }

    const handleCloseMenu = () => {
        setAnchorEl(null)
    }

    const toggleRepliesSection = () => {
        setReplyClicked(!replyClicked)
        setTimeout(() => sizeToContent(trelloIframeContext), 200)
    }

    const handleClickOutside = (event: any) => {
        if (emojiWrapperRef.current && event.target && !emojiWrapperRef.current!.contains(event.target) && (!emojiLinkRef.current || !emojiLinkRef.current.contains(event.target))) {
            closeEmojiPicker()
        }
    }

    const handleEscKeyPress = (event: any) => {
        if (event.keyCode === 27) {
            closeEmojiPicker()
        }
    }

    const openEmojiPicker = () => {
        setEmojiPickerOpened(true)
        resizeIframe(trelloIframeContext)

        document.addEventListener('keydown', handleEscKeyPress, false)
        document.addEventListener('mousedown', handleClickOutside)
        window.addEventListener('blur', closeEmojiPicker)
    }

    const closeEmojiPicker = () => {
        setEmojiPickerOpened(false)
        document.removeEventListener('keydown', handleClickOutside, false)
        document.removeEventListener('mousedown', handleClickOutside)
        window.removeEventListener('blur', closeEmojiPicker)
        resizeIframe(trelloIframeContext)
    }

    const onEmojiClick = async (event: any, emojiData: IEmojiData) => {
        closeEmojiPicker()
        if (props.comment.id) {
            const trelloContext = props.licenseDetails.trelloIframeContext
            const memberId = trelloContext.getContext().member
            const comment = {...props.comment}
            await storeReactionLocally(emojiData, memberId, trelloContext)
            await addOrRemoveReaction(trelloContext, emojiData, comment, memberId)
            props.getComments()
        }
    }

    async function storeReactionLocally(emojiData: IEmojiData, memberId: string, trelloContext: any) {
        if (isReactionRemoved(props.comment, emojiData, memberId)) {
            props.comment.reactions = props.comment.reactions!.filter(reaction => reaction.emoji !== emojiData.emoji ||
                (reaction.emoji === emojiData.emoji && reaction.memberId !== memberId))
        } else {
            props.comment.reactions = [...props.comment.reactions || [], generateReactionData(trelloContext, emojiData)]
        }
        const commentsUpdated = props.comments.map((comment: CommentData) => comment.id === props.comment!.id ? props.comment : comment) as CommentData[]
        await processReactions(props.licenseDetails, props.comment, props.comment.reactions, props.searchAndStoreMember)
        props.setComments(commentsUpdated)
    }

    return <Box display="flex" mb={'8px'}>
        <Box justifyContent="center" className={classes.avatarContainer}>
            {userAvatar}
        </Box>
        <Box className={props.comment.parentCommentId ? classes.commentReplyContainer : classes.commentContainer}
             flexGrow={1} mr={0}>
            <Box display="flex">
                <Box flexGrow={1} display="flex" justifyContent="flex-start">
                    <Box mr={1} className={classes.commentAuthorName}>
                        {props.comment.member?.fullName || props.comment.memberId}
                    </Box>
                    <Box className={classes.commentDate}>
                        {props.comment.id ? props.comment.creationDate.toLocaleString() : ''}
                    </Box>
                </Box>
                <Box
                    className={[(props.comment.parentCommentId ? classes.addReplyReaction : classes.addReaction), showEmojiPickerAbove(emojiLinkRef) ? classes.pickerAboveContainer : ''].join(' ')}>
                    <Link onClick={() => !emojiPickerOpened ? openEmojiPicker() : closeEmojiPicker()}
                          ref={emojiLinkRef}>
                        <EmojiAddIcon label="Add a reaction" size="small"/>
                    </Link>
                    {emojiPickerOpened && <div
                        className={[classes.floatPickerContainer, showEmojiPickerAbove(emojiLinkRef) ? classes.floatPickerContainerThread : ''].join(' ')}
                        hidden={!emojiPickerOpened} ref={emojiWrapperRef}>
                        <Picker
                            onEmojiClick={onEmojiClick}
                            disableAutoFocus={true}
                            native
                        />
                    </div>}
                </Box>

                {!props.replyComment && <Link onClick={() => toggleRepliesSection()}>
                    <Box mr={'18px'}>
                        <CommentIcon label="Reply" size="small"/>
                    </Box>
                </Link>
                }
            </Box>

            <Box display="flex">
                <Box id={props.comment.id} flexGrow={1} mr={props.comment.memberId !== currentMemberId ? '16px' : 0}>
                    {!editingComment && <>
                        <Paper variant="outlined" className={classes.commentContentContainer}>
                            <CommentMarkdownContent commentDisplayContent={props.comment.commentDisplayContent}
                                                    boardAndOrganizationMembers={props.boardAndOrganizationMembers}
                                                    trelloIframeContext={trelloIframeContext}/>
                        </Paper>
                        {props.comment.id &&
                            <Reactions {...props} onEmojiClick={onEmojiClick}/>}
                    </>
                    }
                    {editingComment &&
                    <CommentEditor {...props} comment={props.comment} handleUpdateComment={handleUpdateComment}
                                   boardAndOrganizationMembers={props.boardAndOrganizationMembers}
                                   ref={commentEditorRef} setEditingComment={setEditingComment}/>
                    }
                </Box>
                <Box>
                    {props.comment.memberId === currentMemberId && <>
                        <Link href="#" onClick={(event: any) => handleOpenMenu(event)}>
                            <MoreVerticalIcon label="More actions" size="small"/>
                        </Link>
                        <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)}
                              onClose={() => handleCloseMenu()}>
                            <MenuItem disabled={props.comment.memberId !== currentMemberId}
                                      onClick={() => handleEditComment()}>Edit</MenuItem>
                            <MenuItem disabled={props.comment.memberId !== currentMemberId}
                                      onClick={() => handleRemoveComment()}>Remove</MenuItem>
                        </Menu>
                    </>
                    }
                </Box>
            </Box>
            {!props.comment.parentCommentId &&
            <Replies replyClicked={replyClicked} setReplyClicked={setReplyClicked} {...props}/>
            }
        </Box>
    </Box>
}

export default Comment