import React, {forwardRef, useEffect, useRef, useState} from 'react'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import Button from '@atlaskit/button'
import {Box, Collapse, Grid, Link, Typography} from '@material-ui/core'
import {storeComment} from '../../modules/Persistence'
import EditorEmojiIcon from '@atlaskit/icon/glyph/editor/emoji'
import Picker from 'emoji-picker-react'
import {useStyles} from './CommentEditorStyle'
import Select, {FormatOptionLabelMeta, OptionType} from '@atlaskit/select'
import {CommentEditorProps} from '../../types/CommentEditorProps'
import Member from '../../types/Member'
import CommentData from '../../types/CommentData'
import {v4 as uuidv4} from 'uuid'
import {TrackActionEvent} from 'trello-shared-resources/dist'
import EditorAttachmentIcon from '@atlaskit/icon/glyph/editor/attachment'
import AttachmentDialog from './AttachmentDialog'
import {CardAttachment} from '../../types/CardAttachment'
import InlineDialog from '@atlaskit/inline-dialog'
import {resizeIframe, showEmojiPickerAbove, sizeToContent} from '../../modules/UIUtils'
import {
    addNeededWarnings,
    calculateNewCaretPosition,
    clearWarningsFromComment,
    commentContainsMentionedUserWithoutNotifications,
    getLineNumber,
    removeUnneededWarnings,
    removeWarningAndLastUsernameCharacter
} from '../../modules/TextUtils'
import {convertComment} from '../../modules/CommentService'
import ControlledTextArea from './ControlledTextArea'


const CommentEditor = forwardRef((props: CommentEditorProps, commentEditorRef: any) => {

    const classes = useStyles()

    const trelloIframeContext = props.licenseDetails.trelloIframeContext
    const trelloContextInfo = trelloIframeContext?.getContext()

    const organizationMembersOptions = props.boardAndOrganizationMembers.map(organizationMember => ({
        label: organizationMember.fullName,
        value: organizationMember.username?.startsWith('@') ? organizationMember.username.slice(1) : organizationMember.username,
        isSubscribed: organizationMember.isSubscribed
    })) as OptionType[]

    const [comment] = addNeededWarnings(props.comment?.commentContent || '', props.boardAndOrganizationMembers)

    const [commentContent, setCommentContent] = useState<string>(comment)
    const [selectionStart, setSelectionStart] = useState(0)
    const [emojiPickerOpened, setEmojiPickerOpened] = useState<boolean>(false)
    const [mentionSelectOpened, setMentionSelectOpened] = useState(false)
    const [textAreaRow, setTextAreaRow] = useState(1)
    const [textAreaFocused, setTextAreaFocused] = useState(false)
    const [isAttachmentDialogOpen, _setIsAttachmentDialogOpen] = useState<boolean>(false)
    const [keepAttachmentDialogOpen, _setKeepAttachmentDialogOpen] = useState<boolean>(false)
    const [showWarningUserWontReceiveNotifications, setShowWarningUserWontReceiveNotifications] = useState<boolean>(props.comment?.commentContent ? commentContainsMentionedUserWithoutNotifications(props.comment.commentContent, organizationMembersOptions) : false)
    const isAttachmentDialogOpenRef = useRef(isAttachmentDialogOpen)
    const keepAttachmentDialogOpenRef = useRef(keepAttachmentDialogOpen)

    const setIsAttachmentDialogOpen = (data: boolean) => {
        isAttachmentDialogOpenRef.current = data
        _setIsAttachmentDialogOpen(data)
    }

    const setKeepAttachmentDialogOpen = (data: boolean) => {
        keepAttachmentDialogOpenRef.current = data
        _setKeepAttachmentDialogOpen(data)
    }

    const emojiWrapperRef = useRef<HTMLDivElement | null>(null)
    const emojiLinkRef = useRef<HTMLLinkElement | null>(null)
    const attachmentContentRef = useRef<HTMLDivElement | null>(null)
    const attachmentIconRef = useRef<HTMLDivElement | null>(null)

    const mentionUserSelectRef = useRef<any>(null)
    const collapseMentionsRef = useRef<any>(null)


    useEffect(() => {
        if (commentEditorRef?.current) {
            commentEditorRef.current.addEventListener('mousedown', () => setSelectionStart(commentEditorRef.current.selectionStart))
            commentEditorRef.current.addEventListener('touchstart', () => setSelectionStart(commentEditorRef.current.selectionStart))
            commentEditorRef.current.addEventListener('keyup', () => setSelectionStart(commentEditorRef.current.selectionStart))
            commentEditorRef.current.addEventListener('paste', () => setSelectionStart(commentEditorRef.current.selectionStart))
            commentEditorRef.current.addEventListener('click', () => setSelectionStart(commentEditorRef.current.selectionStart))
            if (commentEditorRef.current.selectionStart !== selectionStart) commentEditorRef.current.selectionStart = commentEditorRef.current.selectionEnd = selectionStart
        }
    }, [commentEditorRef, selectionStart])

    /**
     * Store the comment(root or thread) locally and make the request to store it on the database. Once it's completed, we get the card comments again
     * @param mentionedMembers Member array for the mentioned people
     * @param currentMember member is creating the comment
     */
    async function createNewComment(mentionedMembers: Member[], currentMember: Member | undefined) {
        const creationDate = new Date()
        const clearedCommentContent = clearWarningsFromComment(commentContent)
        const comment: CommentData = {
            creationDate: creationDate,
            cardId: trelloContextInfo?.card,
            commentContent: clearedCommentContent,
            commentDisplayContent: convertComment(clearedCommentContent, mentionedMembers, currentMember!),
            memberId: currentMember?.id,
            member: currentMember,
            parentCommentId: props.parentCommentId,
            mentionedMembers: mentionedMembers,
            id: uuidv4()
        }
        const currentComments: Array<CommentData> = []
        if (props.parentCommentId) {
            const parentComment = props.comments.find(comment => comment.id === props.parentCommentId || comment.idRemovedComment === props.parentCommentId)
            if (parentComment) {
                if (parentComment.threadComments) parentComment.threadComments.push(comment)
                else parentComment.threadComments = [comment]
                const commentsUpdated = props.comments.map((comment: CommentData) => comment.id === props.parentCommentId ? parentComment : comment) as CommentData[]
                currentComments.push(...commentsUpdated)
                props.setComments(commentsUpdated)
            }
        } else {
            const comments = [comment, ...props.comments]
            currentComments.push(...comments)
            props.setComments(comments)
        }

        setShowWarningUserWontReceiveNotifications(false)
        setCommentContent('')
        setTimeout(() => sizeToContent(trelloIframeContext), 200)
        await storeComment({
            trelloContext: trelloIframeContext,
            commentId: comment.id!,
            commentContent: clearedCommentContent,
            parentCommentId: props.parentCommentId ?? '',
            mentionedMembers: mentionedMembers.map(member => member.id),
            currentComments: currentComments,
            boardAndOrganizationMembers: props.boardAndOrganizationMembers,
            creationDate: creationDate
        })
        TrackActionEvent('Comment', trelloContextInfo, {
            board_id: trelloContextInfo?.board,
            card_id: trelloContextInfo?.card,
            action: 'add',
            threaded_comment: props.parentCommentId !== null && props.parentCommentId !== undefined && props.parentCommentId !== '',
            mention_warning: commentContent.length > clearedCommentContent.length,
            mentionedMembers: mentionedMembers.filter((member: Member) => member.id !== currentMember?.id).map(member => member.id)
        })
        props.getComments()
    }

    /**
     * Update the comment locally and make the request to update it on the database. Once it's completed, we get the card comments again
     * @param mentionedMembers Member array for the mentioned people
     * @param currentMember member is updating the comment
     */
    function updateComment(mentionedMembers: Member[], currentMember: Member | undefined) {
        const clearedCommentContent = clearWarningsFromComment(commentContent)
        props.comment!.commentContent = clearedCommentContent
        props.comment!.commentDisplayContent = convertComment(clearedCommentContent, mentionedMembers, currentMember!)
        props.comment!.mentionedMembers = mentionedMembers
        const commentsUpdated = props.comments.map((comment: CommentData) => comment.id === props.comment!.id ? props.comment : comment) as CommentData[]
        props.setComments(commentsUpdated)
        props.setEditingComment!(false)
        props.handleUpdateComment!(clearedCommentContent, mentionedMembers.map(member => member.id))
        TrackActionEvent('Comment', trelloContextInfo, {
            board_id: trelloContextInfo?.board,
            card_id: trelloContextInfo?.card,
            action: 'edit',
            threaded_comment: props.parentCommentId !== null && props.parentCommentId !== undefined && props.parentCommentId !== '',
            mention_warning: commentContent.length > clearedCommentContent.length,
            mentionedMembers: mentionedMembers.filter((member: Member) => member.id !== currentMember?.id).map(member => member.id)
        })
    }

    /**
     * Process the mentions and store or update a comment
     */
    const saveOrUpdateComment = async () => {
        if (commentContent) {
            textAreaFocusHandler()
            const usernamesRegex = new RegExp(/(@(\w|.|-)+? )/gi)
            const mentionedMembers = commentContent.match(usernamesRegex)?.map(mentionedMember =>
                (props.boardAndOrganizationMembers.find(organizationMember => `@${organizationMember.username} ` === mentionedMember))
            )
                .filter((mentionedMember: Member | undefined) => mentionedMember) as Member[] || []

            const currentMemberId = trelloContextInfo?.member
            const currentMember = props.boardAndOrganizationMembers.find(boardAndOrganizacionMember => boardAndOrganizacionMember.id === currentMemberId)
            if (props.comment && props.comment.id && props.handleUpdateComment) {
                updateComment(mentionedMembers, currentMember)
            } else {
                await createNewComment(mentionedMembers, currentMember)
            }
        }
    }

    const onEmojiClick = (event: any, emojiObject: any) => {
        setCommentContent(`${commentContent.slice(0, selectionStart)}${emojiObject.emoji} ${commentContent.slice(selectionStart)}`)
        setSelectionStart(selectionStart + 3)
        closeEmojiPicker()
    }

    function handleClickOutsideEmoji(event: any) {
        if (emojiWrapperRef.current && event.target && !emojiWrapperRef.current!.contains(event.target) && (!emojiLinkRef.current || !emojiLinkRef.current.contains(event.target))) {
            closeEmojiPicker()
        }
    }

    function handleEscKeyPressEmoji(event: any) {
        if (event.keyCode === 27) closeEmojiPicker()
    }

    function handleClickOutsideMentionUser(event: any) {
        if (collapseMentionsRef.current && event.target && !collapseMentionsRef.current!.contains(event.target)) {
            closeMentionUserSelect()
        }
    }

    function handleEscKeyPressMentionUser(event: any) {
        if (event.keyCode === 27) closeMentionUserSelect()
    }

    function openEmojiPicker() {
        setEmojiPickerOpened(true)
        resizeIframe(trelloIframeContext)
        document.addEventListener('keydown', handleEscKeyPressEmoji, false)
        document.addEventListener('mousedown', handleClickOutsideEmoji)
        window.addEventListener('blur', closeEmojiPicker)
    }

    function closeEmojiPicker() {
        setEmojiPickerOpened(false)
        document.removeEventListener('keydown', handleClickOutsideEmoji, false)
        document.removeEventListener('mousedown', handleClickOutsideEmoji)
        window.removeEventListener('blur', closeEmojiPicker)
        resizeIframe(trelloIframeContext)
    }


    const openMentionUserSelect = (event: any) => {
        setSelectionStart(event.target.selectionStart)
        setMentionSelectOpened(true)
        setTextAreaRow(getLineNumber(event.target))
        setTimeout(() => mentionUserSelectRef.current.focus(), 200)
        document.addEventListener('keydown', handleEscKeyPressMentionUser, false)
        document.addEventListener('mousedown', handleClickOutsideMentionUser)
    }

    /**
     * Text area handler to check if the user want to mention someone
     * @param event the onChange event
     */
    const textAreaOpenHandler = (event: any) => {
        const newComment = event.target.value
        const currentCaretPosition = event.target.selectionStart
        const lastCharacter = newComment.charAt(currentCaretPosition - 1)
        const prevLastCharacter = newComment.charAt(currentCaretPosition - 2)
        const prevLastCharacterIsLetterOrNumber = prevLastCharacter.match(/^\w+$/g)

        if (lastCharacter === '@' && (!prevLastCharacter || !prevLastCharacterIsLetterOrNumber)) {
            openMentionUserSelect(event)
        } else if (mentionSelectOpened && [' ', ''].includes(lastCharacter)) {
            closeMentionUserSelect()
        }

        setShowWarningUserWontReceiveNotifications(commentContainsMentionedUserWithoutNotifications(newComment, organizationMembersOptions))

        const [editedComment, caretPositionAfterRemovingLastWarning] = removeWarningAndLastUsernameCharacter(newComment, commentContent, organizationMembersOptions, currentCaretPosition)
        const [newCommentWillAllNewWarnings, warningsAdded] = addNeededWarnings(editedComment || newComment, props.boardAndOrganizationMembers)
        const [newCommentUpdated, warningsRemoved] = removeUnneededWarnings(newCommentWillAllNewWarnings, organizationMembersOptions)

        setCommentContent(newCommentUpdated)
        const removingCharacters = newCommentUpdated.length < commentContent.length
        if (removingCharacters || caretPositionAfterRemovingLastWarning || warningsAdded > 0 || warningsRemoved > 0) {
            const newCaretPosition = calculateNewCaretPosition(caretPositionAfterRemovingLastWarning || currentCaretPosition, warningsAdded, warningsRemoved)
            setSelectionStart(newCaretPosition)
        } else {
            setSelectionStart(currentCaretPosition)
        }
    }

    const closeMentionUserSelect = () => {
        setMentionSelectOpened(false)
        commentEditorRef.current.focus()
        document.removeEventListener('keydown', handleEscKeyPressMentionUser, false)
        document.removeEventListener('mousedown', handleClickOutsideMentionUser)
    }

    const selectMemberHandler = (memberOption: any) => {
        setCommentContent(`${commentContent.slice(0, selectionStart)}${memberOption.value} ${memberOption.isSubscribed ? '' : '(⚠️) '}${commentContent.slice(selectionStart)}`)
        setMentionSelectOpened(false)
        commentEditorRef.current.focus()
        if (!memberOption.isSubscribed) setShowWarningUserWontReceiveNotifications(true)
        const warningIconCharacters = memberOption.isSubscribed ? 0 : 5
        const caretPosition = selectionStart + memberOption.value.length + 1 + warningIconCharacters
        setTimeout(() => commentEditorRef.current.setSelectionRange(caretPosition, caretPosition), 200)
    }

    function handleBlurTextArea() {
        if (!isAttachmentDialogOpenRef.current && !keepAttachmentDialogOpenRef.current) setTextAreaFocused(false)
    }

    function handleClickOutsideTextArea(event: any) {
        if (!isAttachmentDialogOpenRef.current && commentEditorRef.current && event.target && !commentEditorRef.current!.contains(event.target) &&
            attachmentIconRef.current && !attachmentIconRef.current!.contains(event.target) && !clickedInsideAttachmentDialog(event)) {
            setTextAreaFocused(false)

            if (!emojiPickerOpened) setTimeout(() => sizeToContent(trelloIframeContext), 100)
            document.removeEventListener('mousedown', handleClickOutsideTextArea)
            window.removeEventListener('blur', handleBlurTextArea)
        }
    }

    const textAreaFocusHandler = () => {
        setTextAreaFocused(true)
        setTimeout(() => sizeToContent(trelloIframeContext), 100)
        document.removeEventListener('mousedown', handleClickOutsideTextArea)
        document.addEventListener('mousedown', handleClickOutsideTextArea)
        window.removeEventListener('blur', handleBlurTextArea)
        window.addEventListener('blur', handleBlurTextArea)
    }

    const toggleAttachmentDialog = () => {
        if (!isAttachmentDialogOpen) {
            setIsAttachmentDialogOpen(true)
            document.removeEventListener('mousedown', handleClickOutsideTextArea)
            window.removeEventListener('blur', handleBlurTextArea)
            document.addEventListener('mousedown', handleCloseAttachmentDialog)
            window.addEventListener('blur', handleCloseAttachmentDialog)
        } else closeAttachmentDialog()
    }

    const clickedInsideAttachmentDialog = (event: any) =>
        (attachmentContentRef.current && attachmentContentRef.current.parentElement && event.target
            && attachmentContentRef.current.parentElement.contains(event.target)) ||
        (attachmentIconRef && attachmentIconRef.current && attachmentIconRef.current.contains(event.target))

    function handleCloseAttachmentDialog(event: any) {
        if (!keepAttachmentDialogOpenRef.current && (event.type === 'blur' || !clickedInsideAttachmentDialog(event))) {
            closeAttachmentDialog()
        }
    }

    const closeAttachmentDialog = () => {
        setTextAreaFocused(true)
        setKeepAttachmentDialogOpen(false)
        setIsAttachmentDialogOpen(false)
        document.removeEventListener('mousedown', handleCloseAttachmentDialog)
        window.removeEventListener('blur', handleCloseAttachmentDialog)
        document.addEventListener('mousedown', handleClickOutsideTextArea)
        window.addEventListener('blur', handleBlurTextArea)
        setTimeout(() => commentEditorRef.current.focus(), 200)
    }

    const handlerAttachmentSelected = (attachment: CardAttachment) => {
        setCommentContent(`${commentContent.slice(0, selectionStart)}[${attachment.name}](${attachment.url}) ${commentContent.slice(selectionStart)}`)
        closeAttachmentDialog()
        const caretPosition = selectionStart + attachment.name.length + attachment.url.length + 5
        setSelectionStart(caretPosition)
        setTimeout(() => commentEditorRef.current.setSelectionRange(caretPosition, caretPosition), 200)
    }

    const formatOptionLabel = (option: OptionType, {context}: FormatOptionLabelMeta<OptionType>) => {
        if (context === 'menu') {
            return (
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <span style={{paddingLeft: 8, paddingBottom: 0}}>
                        {option.label}
                        {option.isSubscribed ? '' : <> (
                            <span
                                className={classes.warningIcon}
                                role="img">⚠️️</span>)</>}
                    </span>
                </div>
            )
        }
        return option.label
    }

    const notificationLinkHandler = () => {
        TrackActionEvent('Email notification docs link', trelloContextInfo, {
            board_id: trelloContextInfo.board,
            member_id: trelloContextInfo.member,
            action: 'warning_new_comment'
        })
    }

    const calculateSelectMentionPosition = () => textAreaRow * 20 + 15 - (commentEditorRef?.current?.scrollTop || 0)

    return (
        <>
            <Box className={[classes.commentEditorContainer,
                props.parentCommentId ? classes.commentEditorRepliesContainer : '',
                commentContent.length === 0 ? classes.commentEditorEmpty : ''].join(' ')}>
                {emojiPickerOpened && <div
                    className={[classes.floatPickerContainer, showEmojiPickerAbove(emojiLinkRef) ? classes.floatPickerContainerThread : classes.floatPickerContainerRoot].join(' ')}
                    ref={emojiWrapperRef}>
                    <Picker
                        onEmojiClick={onEmojiClick}
                        disableAutoFocus={true}
                        native
                    />
                </div>}
                <ControlledTextArea placeholder={props.defaultText}
                                    onChange={(event: any) => textAreaOpenHandler(event)}
                                    onFocus={() => textAreaFocusHandler()}
                                    value={commentContent}
                                    ref={commentEditorRef}
                />
                <Collapse in={mentionSelectOpened} className={classes.mentionContainer}
                          style={{top: calculateSelectMentionPosition(), left: 20}} innerRef={collapseMentionsRef}
                >
                    <Select options={organizationMembersOptions}
                            onChange={selectMemberHandler}
                            formatOptionLabel={formatOptionLabel}
                            ref={mentionUserSelectRef}
                            value={null}
                            testId="organizationMembersOptions"
                            menuPlacement="auto"
                    />
                </Collapse>
                <Box mr={'20px'}>
                    <Link onClick={!emojiPickerOpened ? openEmojiPicker : closeEmojiPicker} ref={emojiLinkRef}
                          className={classes.emojiLink}>
                        <EditorEmojiIcon label="Add an emoji" size="medium"/>
                    </Link>
                </Box>
            </Box>
            {showWarningUserWontReceiveNotifications &&
                <Box sx={{display: 'flex', flexDirection: 'row'}} className={classes.warningContainer}>
                    <Box className={classes.warningIconContainer}>⚠️</Box>
                    <Typography className={classes.warningExplain}>
                        The user you tagged will not receive any notifications about this comment
                        unless they sign up for email notifications. They can sign up by following these steps - please send them this <Link
                        className={classes.notificationsSignUpLink} target="_blank" onClick={notificationLinkHandler}
                        href={process.env.REACT_APP_NOTIFICATIONS_SIGN_UP_DOCS_LINK}>
                        link
                    </Link>.
                    </Typography>
                </Box>}
            {(textAreaFocused || (commentContent && commentContent.length > 0)) &&
                <Grid container alignContent="flex-end" direction="row">
                    <div>
                        <InlineDialog
                            content={<AttachmentDialog
                                ref={attachmentContentRef}
                                licenseDetails={props.licenseDetails}
                                handlerAttachmentSelected={handlerAttachmentSelected}
                                setKeepAttachmentDialogOpen={setKeepAttachmentDialogOpen}
                            />}
                            isOpen={isAttachmentDialogOpen}
                            placement={'auto'}
                        >
                            <div ref={attachmentIconRef} onClick={toggleAttachmentDialog}>
                                <EditorAttachmentIcon label="Attach file"/>
                            </div>
                        </InlineDialog>
                    </div>
                    <Box className={props.parentCommentId ? classes.saveReplyContainer : classes.saveContainer}>
                        <Button appearance="primary" onClick={saveOrUpdateComment}
                                isDisabled={commentContent.length === 0}>Save</Button>
                    </Box>
                </Grid>
            }
        </>
    )
})

export default React.memo(CommentEditor)