import React, { useEffect, useState } from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import CrossIcon from '@atlaskit/icon/glyph/cross'
import { useStyles } from './GetStartedStyles'
import Button from '@atlaskit/button'
import {
    getStartedButtonText,
    getStartedContent,
    getStartedContentBold,
    getStartedSubContent,
    getStartedTitle
} from '../onboarding/email-notifications-messages'
import { hasUserDismissMiniOnboarding, setUserDismissMiniOnboarding } from '../../../modules/TrelloPersistence'
import IconWithTitle from '../commonComponents/IconWithTitle'
import { EmailNotificationEventType, trackEmailNotificationEvent } from '../onboarding/EmailNotificationsUtils'
import { getUserEmailForNotifications } from '../../../modules/Persistence'

/**
 * Mini Onboarding. Shown for "no-admin" users and for "admin" users that skipped the email notification onboarding
 */
const GetStarted = (props: { licenseDetails: any }) => {
    const classes = useStyles()
    const [isDismissed, setIsDismissed] = useState<boolean>(true)
    const [isUserSubscribed, setIsUserSubscribed] = useState<boolean>(true)

    const showGetStarted = async () => {
        const trelloIframeContext = props.licenseDetails.trelloIframeContext
        setIsDismissed(await hasUserDismissMiniOnboarding(trelloIframeContext))

        const emailData = await getUserEmailForNotifications(trelloIframeContext)
        setIsUserSubscribed(emailData && emailData.email !== undefined)
    }

    useEffect(() => {
            showGetStarted()
        }, // eslint-disable-next-line
        [props.licenseDetails.trelloIframeContext])

    /**
     * Launches the mini onboarding modal window
     */
    const openMiniOnbardingModal = async () => {
        const trelloIframeContext = props.licenseDetails.trelloIframeContext
        trackEmailNotificationEvent(props.licenseDetails.trelloIframeContext.getContext(), EmailNotificationEventType.START)
        trelloIframeContext.modal({
            url: './index.html?apptype=miniOnboarding',
            height: 680,
            title: process.env.REACT_APP_TRELLO_APP_NAME,
            accentColor: '#EBEDF0',
            callback: () => {
                showGetStarted()
            }
        })
    }

    const closeIconHandler = () => {
        trackEmailNotificationEvent(props.licenseDetails.trelloIframeContext.getContext(), EmailNotificationEventType.REJECT)
        setUserDismissMiniOnboarding(props.licenseDetails)
        setIsDismissed(true)
    }

    return (
        <>
            {!isDismissed && !isUserSubscribed &&
            <Grid container spacing={0} className={classes.grid}>
                <Grid container alignContent="flex-end" direction="column">
                    <Box className={classes.closeIcon} onClick={closeIconHandler}>
                        <CrossIcon label="close"/>
                    </Box>
                </Grid>
                <IconWithTitle title={getStartedTitle}/>
                <Box>
                    <Typography className={classes.content}>
                        {getStartedContent} <span className={classes.boldElements}>{getStartedContentBold}</span>
                    </Typography>
                </Box>
                <Box>
                    <Typography className={classes.content}>
                        {getStartedSubContent}
                    </Typography>
                </Box>
                <Grid container alignContent="flex-end" direction="column">
                    <Box className={classes.boldElements}>
                        <Button appearance="primary" onClick={openMiniOnbardingModal}>{getStartedButtonText}</Button>
                    </Box>
                </Grid>
            </Grid>
            }
        </>
    )
}

export default GetStarted