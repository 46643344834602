import { makeStyles } from '@material-ui/core/styles'
import { createStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
            grid: {
                padding: 24,
                marginBottom: 10,
                backgroundColor: 'white'
            },
            appIcon: {
                height: 38,
                width: 38,
                borderRadius: 20,
                backgroundColor: '#00C0C2',
                marginRight: 12
            },
            title: {
                marginTop: 6,
                fontSize: 20,
                fontFamily: 'SF UI Text Regular',
                color: '#172B4D',
                letterSpacing: 0,
                lineHeight: '29px'
            },
            content: {
                marginTop: 18,
                fontSize: 14,
                whiteSpace: 'pre-wrap',
                fontFamily: 'SF Pro Text Regular',
                letterSpacing: 0,
                color: '#172B4D',
                lineHeight: '20px'
            },
            boldElements: {
                fontFamily: 'SF Pro Text Bold',
            },
            closeIcon: {
                cursor: 'pointer',
                margin: -8,
            },
        }
    )
)

export { useStyles }


