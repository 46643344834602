/**
 * Email Notifications - Onboarding messages
 */
export const emailNotificationsTitle = 'Email Notifications'
export const emailNotificationsSectionMessage = 'Due to Trello restrictions, Threaded Comments currently does not support in-app notifications. However, opting into email notifications allows you to receive notice when a team member mentions you in a message.'
export const emailNotificationsSectionMessageAction = 'Find out more'
export const emailNotificationsBody1 = 'Want to be sure you’re staying up to date with Threaded Comments? The best way is to sign up for our email notifications!'
export const emailNotificationsInfoMessage = 'After signing up, you’ll only receive correspondence related to Threaded Comments notifications. You can unsubscribe at any time.'
export const emailNotificationsEmailText = 'Please enter the email address you’d like us to send email notifications.'
export const emailNotificationsEulaText = 'I agree to Orah Apps <a target="_blank" href="https://www.orahapps.com/privacy">Privacy Policy</a>, <a target="_blank" href="https://www.orahapps.com/terms">Terms of use</a>, and <a target="_blank" href="https://www.orahapps.com/eula">EULA</a>'
export const emailNotificationsFaqLink = 'https://docs.orahapps.com/en/articles/67-notifications'

/**
 * Email Notifications - Email success
 */
export const emailNotificationsStoredBody1 = 'Success! 🥳 You\'re signed up for email notifications.'
export const emailNotificationsStoredBody2 = 'You can change your notification settings anytime via the '
export const emailNotificationsStoredBody3 = 'settings page'
export const emailNotificationsStoredBody4 = '.'
export const emailNotificationsStoredTitle2 = 'Keep Your Teammates Up To Date'
export const emailNotificationsStoredBody5 = 'Want your teammates to be notified when you tag them in a message too? Just share this '
export const emailNotificationsStoredBody6 = 'link'
export const emailNotificationsStoredBody7 = ' with them so they can sign up for email notifications.'
export const emailNotificationsStoredBody8 = 'You\'re all set, and can start using Threaded Comments! 🎉'
export const emailNotificationsStoredButton = 'Take me to my board'

/**
 * Email Notifications - Email dismiss
 */
export const emailNotificationsDismissTitle = 'You can start using Threaded Comments'
export const emailNotificationsDismissSubtitle = 'You\'ve opted not to receive notification emails.'
export const emailNotificationsDismissBody1 = 'If you change your mind you can sign up anytime via the '
export const emailNotificationsDismissBody2 = 'settings page'
export const emailNotificationsDismissBody3 = '.'
export const emailNotificationsDismissBody4 = 'You\'re all set, and can start using Threaded Comments! 🎉'
export const emailNotificationsDismissButton = 'Take me to my board'

/**
 * Mini Onboarding - Get Started
 */
export const getStartedTitle = 'Welcome to Threaded Comments for Trello'
export const getStartedContent = 'One of your teammates installed this app to'
export const getStartedContentBold = 'help you boost your communication in Trello!'
export const getStartedSubContent = 'Let’s get you set up….'
export const getStartedButtonText = 'Get Started'
export const returnToTrelloButtonText = 'Return to Trello'

/**
 * Email Notifications - Settings
 */

export const emailNotificationSettingTitle = 'Notification Settings'
export const emailNotificationSettingInvalidEmail = 'Please use a valid email address.'
export const emailNotificationSettingMandatoryEmail = 'Please enter a valid email address.'
export const emailNotificationSettingMandatoryEULA = 'You must agree to enable email notifications'
export const emailNotificationSettingHelpTextNewEmail = 'This is the email where you receive email notifications'
export const emailNotificationSettingActiveStatus = 'ACTIVE'
export const emailNotificationSettingInactiveStatus = 'INACTIVE'
export const emailNotificationSettingBody1 = 'To change your email notification preference, use the toggle switch below.'
export const emailNotificationSettingBody2 = 'You have opted to receive email notifications'
export const emailNotificationSettingBody2dot5 = 'To change your email notification preference, click the Unsubscribe link below.'
export const emailNotificationSettingBody3 = 'Unsubscribing might take up to 24 hours to take effect.'
export const emailNotificationSettingBody4 = 'Email Address'
export const emailNotificationSettingBody5 = 'If you want to change which email address your notifications are sent to, edit the field below and click Submit again.'
export const emailNotificationSettingUnsubscribeButton = 'Unsubscribe'
export const emailNotificationSettingSubmitButton = 'Submit'
export const emailNotificationSettingError = 'Something went wrong. Please try again or contact our support team.'
export const emailNotificationSettingTitleError = 'Oops! 😅'
export const emailNotificationSettingContactSupport = 'Contact Support'
export const emailNotificationSettingClose = 'Close'
export const emailNotificationSettingGoToBoard = 'Return to Trello board'
export const emailNotificationSettingUpdateSuccessTitle = 'Success! 🥳'
export const emailNotificationSettingEmailUpdated = 'Your email has been updated.'
export const emailNotificationSettingEmailLabel = 'Email address'
export const emailNotificationSettingUnsubscribeSuccessTitle = 'You have successfully unsubscribed.'
export const emailNotificationSettingUnsubscribeSuccessBody = 'If you\'ve clicked this option by mistake or would like to resubscribe to emails in the future, please click '
export const emailNotificationSettingUnsubscribeSuccessBody2 = 'here'
export const emailNotificationSettingUnsubscribeSuccessBody3 = '.'
export const emailNotificationSettingFeedbackSuccessTitle = 'Thank you for your response.'

/**
 * Unsubsbribe - outside
 */
export const unsubscribePageTitle = 'Threaded Comments by Orah Apps'
export const unsubscribeUnsubscribedBody1 = 'You are unsubscribed from email notifications. If you would like to subscribe please follow '
export const unsubscribeUnsubscribedBody2 = 'these steps'
export const unsubscribeSuccessfullyTitle = 'You have been successfully unsubscribed.'
export const unsubscribeSuccessfullyBody = 'If you\'ve clicked this option by mistake or would like to resubscribe to emails in the future, please follow '
export const unsubscribeErrorTitle = 'Unsubscribe unsuccessful.'
export const unsubscribeErrorBody = 'Sorry, there\'s been a problem changing your subscription preferences. Please try again. If the problem persists, don\'t hesitate to contact our '
export const unsubscribeErrorLinkText = 'Support Team'
export const unsubscribeFeedbackSuccessBody1 = 'Thank you for your response. Click '
export const unsubscribeFeedbackSuccessLink = 'here'
export const unsubscribeFeedbackSuccessBody2 = ' to return to Trello.'
export const unsubscribeFeedbackExplain = 'If you have a moment, please let us know why you unsubscribed. Then click Submit.'
export const unsubscribeFeedbackReason1 = 'I no longer want to receive these email notifications'
export const unsubscribeFeedbackReason2 = 'Too many emails'
export const unsubscribeFeedbackReason3 = 'I don’t remember signing up'
export const unsubscribeFeedbackReason4 = 'I no longer use Threaded Comments for Trello'
export const unsubscribeFeedbackReason5 = 'Other (fill in reason below)'
export const unsubscribeFeedbackTextAreaPlaceholder = 'Add your reason here.'
export const unsubscribeFeedbackSubmit = 'Submit'
export const unsubscribeFeedbackCancel = 'Cancel'
export const unsubscribeFeedbackErrorTitle = 'Error on submit'
export const unsubscribeFeedbackErrorBody = 'Sorry there was a problem! Please try submitting your response again.'
export const unsubscribeFeedbackErrorGoBack = 'Go back'
export const unsubscribeFeedbackLoadingErrorTitle = 'Oops - looks like you took a wrong turn.'
export const unsubscribeFeedbackLoadingErrorBody1 = 'Please click '
export const unsubscribeFeedbackLoadingErrorBody2 = ' to return to Trello. Alternatively, if you need help with Threaded Comments please see our '
export const unsubscribeFeedbackLoadingErrorBody3 = ' or raise a support ticket '
export const unsubscribeFeedbackLoadingErrorBody4 = '.'
export const unsubscribeFeedbackLoadingErrorLinkText1 = 'here'
export const unsubscribeFeedbackLoadingErrorLinkText2 = 'documentation'