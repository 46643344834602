import React from 'react'
import ReactDOM from 'react-dom'

// Import the views your app loads
import Connector from './views/Connector'
import {appDetailsState, initSentry, LoadAnalytics, Onboarding, writeLog} from 'trello-shared-resources'

// Globally load the ant design css
import 'antd/dist/antd.css'

// Load the main Orah CSS
import 'trello-shared-resources/dist/assets/fonts/font.css'
import './css/transitions.css'

import ThreadedComments from './components/ThreadedComments'
import EmailNotificationsCheckoutFlow from './components/emailNotifications/onboarding/EmailNotificationsCheckoutFlow'
import ErrorComponent from 'trello-shared-resources/dist/components/onboarding/error/ErrorComponent'
import ThreadedCommentsSettings from './components/settings/ThreadedCommentsSettings'
import MiniOnboarding from './components/emailNotifications/miniOnboarding/MiniOnboarding'
import UnsubscribeDisplay from "./components/unsubscribe/UnsubscribeDisplay"

/*
  Parse query string for "apptype" and route to corresponding view
*/
const queryString = require('query-string')
const parsedQueryString = queryString.parse(window.location.search)
const iframePassedAppType = parsedQueryString['apptype']
writeLog('Iframe was passed apptype : ' + iframePassedAppType)

LoadAnalytics()
const beforeSend = (event, hint) => {
    if (hint.originalException.message === 'Network Error') {
        return
    }
    return event
}
initSentry('https://9681e3c98f604187b38444ed01c62fd2@o82263.ingest.sentry.io/6092155', beforeSend)


switch (iframePassedAppType) {
    case 'threadedcomments':
        ReactDOM.render(<ThreadedComments/>,
            document.getElementById('root'))
        break
    case 'emailNotificationsOnboarding':
        ReactDOM.render(<EmailNotificationsCheckoutFlow/>,
            document.getElementById('root'))
        break
    case 'onboarding':
        ReactDOM.render(<Onboarding context={appDetailsState}/>, document.getElementById('root'))
        break
    case 'miniOnboarding':
        ReactDOM.render(<MiniOnboarding/>, document.getElementById('root'))
        break
    case 'settings':
        ReactDOM.render(<ThreadedCommentsSettings context={appDetailsState}/>, document.getElementById('root'))
        break
    case 'error':
        ReactDOM.render(<ErrorComponent/>, document.getElementById('root'))
        break
    case 'unsubscribe':
        ReactDOM.render(<UnsubscribeDisplay memberId={parsedQueryString['memberId']}
                                            emailSubscriptionId={parsedQueryString['emailSubscriptionId']}
        />, document.getElementById('root'))
        break
    case 'comment':
        localStorage.setItem('commentId', parsedQueryString['commentId'])
        window.location.href = parsedQueryString['linkcard']
        break
    default: // We not pass a querystring param for the App Connector
        // This connector is used to instantiate your app's capabilities
        ReactDOM.render(<Connector/>, document.getElementById('root'))
        break
}