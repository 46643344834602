import React, { useEffect, useState } from 'react'
import { Grid, Link, Typography } from '@material-ui/core'
import { useStyles } from './NotificationSettingsStyles'
import Lozenge from '@atlaskit/lozenge'
import { getUserEmailForNotifications } from '../../../modules/Persistence'
import { Skeleton } from 'antd'
import UnauthorizedStep
    from 'trello-shared-resources/dist/components/onboarding/unauthorized/unauthorizedStep/UnauthorizedStep'
import { UserEmailNotificationStored } from '../../../types/UserEmailNotificationStored'
import NotificationUpdateSuccessMessage from './NotificationUpdateSuccessMessage'
import { NotificationSettingsProperties } from '../../../types/NotificationSettingsProperties'
import {
    emailNotificationSettingActiveStatus,
    emailNotificationSettingClose,
    emailNotificationSettingEmailUpdated,
    emailNotificationSettingFeedbackSuccessTitle,
    emailNotificationSettingGoToBoard,
    emailNotificationSettingInactiveStatus,
    emailNotificationSettingTitle,
    emailNotificationSettingUnsubscribeSuccessBody,
    emailNotificationSettingUnsubscribeSuccessBody2,
    emailNotificationSettingUnsubscribeSuccessBody3,
    emailNotificationSettingUnsubscribeSuccessTitle,
    emailNotificationSettingUpdateSuccessTitle,
    emailNotificationsTitle,
    unsubscribeErrorBody,
    unsubscribeErrorLinkText,
    unsubscribeErrorTitle
} from '../../emailNotifications/onboarding/email-notifications-messages'
import NotificationSettingsForm from './NotificationSettingsForm'
import NotificationUnsubscribeSection from './NotificationUnsubscribeSection'
import UnsubscribeFeedbackForm from '../../unsubscribe/UnsubscribeFeedbackForm'
import { SectionMessageAction } from '@atlaskit/section-message'
import UnsubscribeResultMessage from '../../unsubscribe/UnsubscribeSuccessMessage'

const NotificationSettings = (props: NotificationSettingsProperties) => {

    const classes = useStyles()

    const [userEmail, setUserEmail] = useState<string | undefined>('')
    const [isLoading, setIsLoading] = useState(true)
    const [showErrorPage, setShowErrorPage] = useState(false)
    const [successUpdate, setSuccessUpdate] = useState(false)
    const [errorUpdate, setErrorUpdate] = useState(false)
    const [successFeedbackForm, setSuccessFeedbackForm] = useState(false)

    const licenseDetails = props.licenseDetails
    const trelloIframeContext = licenseDetails.trelloIframeContext
    const isEmailsNotificationsActive = userEmail !== undefined && userEmail !== ''

    useEffect(() => {
        if (trelloIframeContext && trelloIframeContext.getContext) {
            getUserEmailForNotifications(trelloIframeContext).then((result: UserEmailNotificationStored) => {
                setUserEmail(result.email || '')
                setIsLoading(false)
            }).catch(() => {
                setIsLoading(false)
                setShowErrorPage(true)
            })
        }
    }, [trelloIframeContext])


    if (licenseDetails.isLoading || isLoading) {
        return <Grid item xs={8} md={10} xl={9} className={classes.contentContainer}>
            <Skeleton active={true}/>
        </Grid>
    }

    if (showErrorPage) {
        return <UnauthorizedStep licenseDetails={licenseDetails}/>
    }

    return <Grid item xs={8} md={6} className={classes.contentContainer}>
        <Typography variant={'h1'} className={classes.title}>{emailNotificationSettingTitle}</Typography>

        {successUpdate && (isEmailsNotificationsActive || successFeedbackForm) && <NotificationUpdateSuccessMessage
            titleText={successFeedbackForm ? emailNotificationSettingFeedbackSuccessTitle : emailNotificationSettingUpdateSuccessTitle}
            actions={[
                <SectionMessageAction onClick={() => setSuccessUpdate(false)}>
                    {emailNotificationSettingClose}
                </SectionMessageAction>,
                <SectionMessageAction onClick={() => trelloIframeContext.closeModal()}>
                    {emailNotificationSettingGoToBoard}
                </SectionMessageAction>
            ]}
        >
            <>{successFeedbackForm ? '' : emailNotificationSettingEmailUpdated}</>
        </NotificationUpdateSuccessMessage>
        }

        {successUpdate && !isEmailsNotificationsActive && !successFeedbackForm &&
        <NotificationUpdateSuccessMessage titleText={emailNotificationSettingUnsubscribeSuccessTitle}
                                          actions={[]}
        >
            <>
                {emailNotificationSettingUnsubscribeSuccessBody}
                <Link onClick={() => setSuccessUpdate(false)}>
                    {emailNotificationSettingUnsubscribeSuccessBody2}
                </Link>
                {emailNotificationSettingUnsubscribeSuccessBody3}
            </>
        </NotificationUpdateSuccessMessage>}

        {errorUpdate &&
        <UnsubscribeResultMessage appearance={'error'} title={unsubscribeErrorTitle} body={unsubscribeErrorBody}
                                  linkText={unsubscribeErrorLinkText}
                                  link={process.env.REACT_APP_TRELLO_APP_SUPPORT_LINK!}/>
        }

        {!(!isEmailsNotificationsActive && successUpdate && !successFeedbackForm) &&
        <Typography variant={'h2'} className={classes.subtitle}>
            {emailNotificationsTitle}
            <Lozenge appearance={isEmailsNotificationsActive ? 'success' : 'removed'} isBold>
                {isEmailsNotificationsActive ? emailNotificationSettingActiveStatus : emailNotificationSettingInactiveStatus}
            </Lozenge>
        </Typography>
        }

        {isEmailsNotificationsActive &&
        <NotificationUnsubscribeSection trelloIframeContext={trelloIframeContext} userEmail={userEmail}
                                        setUserEmail={setUserEmail}
                                        setErrorUpdate={setErrorUpdate} setSuccessUpdate={setSuccessUpdate}
                                        setSuccessFeedbackForm={setSuccessFeedbackForm}
        />
        }

        {!(!isEmailsNotificationsActive && successUpdate && !successFeedbackForm) &&
        <NotificationSettingsForm trelloIframeContext={trelloIframeContext} userEmail={userEmail}
                                  setUserEmail={setUserEmail}
                                  setErrorUpdate={setErrorUpdate} setSuccessUpdate={setSuccessUpdate}
                                  setSuccessFeedbackForm={setSuccessFeedbackForm}
        />
        }

        {!isEmailsNotificationsActive && successUpdate && !successFeedbackForm &&
        <UnsubscribeFeedbackForm setSuccessFeedbackForm={setSuccessFeedbackForm}
                                 trelloIframeContext={trelloIframeContext}
                                 emailSubscriptionId={''}
                                 setSuccessUpdate={setSuccessUpdate}/>}
    </Grid>
}

export default NotificationSettings

