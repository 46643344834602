import React, {useCallback, useEffect, useRef, useState} from 'react'
import CommentEditor from './commentEditor/CommentEditor'
import UnauthorizedStep
    from 'trello-shared-resources/dist/components/onboarding/unauthorized/unauthorizedStep/UnauthorizedStep'
import CommentList from './CommentList'
import {
    getCommentsForCard,
    getMemberById,
    getMembershipsForBoard, getMembersSubscriptionForNotifications,
    getOrganizationMembersOnCurrentBoard,
    markCommentAsRead,
    uniqueMemberCalculator,
} from '../modules/Persistence'
import CommentData from '../types/CommentData'
import {renderTrelloContextMissing} from 'trello-shared-resources/dist'
import {useStyles} from './ThreadedCommentsDisplayStyle'
import GetStarted from './emailNotifications/miniOnboarding/GetStarted'
import SettingsMenuButton from './settings/settingsMenuButton/SettingsMenuButton'
import Member from '../types/Member'
import {CommentsForCardResult} from '../types/CommentsForCardResult'
import {createEvalLicense} from 'trello-shared-resources/dist/services/LicenseService'
import {Skeleton} from 'antd'
import NotLoggedInOrahError from './notLoggedInOrahError/NotLoggedInOrahError'
import {processComments} from '../modules/CommentService'
import {sizeToContent} from '../modules/UIUtils'

const ThreadedCommentsDisplay = (props: { licenseDetails: any }) => {

    const classes = useStyles()

    const [isLoading, setIsLoading] = useState(true)
    const [comments, setComments] = useState<Array<CommentData>>([])
    const [refreshTimeout, setRefreshTimeout] = useState<any>(undefined)
    const [orahUser, setOrahUser] = useState<any>(undefined)
    const [notLoggedInOrahUser, setNotLoggedInOrahUser] = useState<any>(false)
    const [boardAndOrganizationMembers, setBoardAndOrganizationMembers] = useState<Array<Member>>([])
    const commentEditorRef = useRef<HTMLTextAreaElement | null>(null)
    const [commentIdToScroll, setCommentIdToScroll] = useState<string | undefined>(undefined)

    const members = useRef<any>([])
    const licenseDetails = props.licenseDetails
    const trelloIframeContext = licenseDetails.trelloIframeContext

    const updateMembers = useCallback(async (updatedBoardAndOrganizationMembers: Array<Member>) => {
        await getMembersSubscriptionForNotifications(trelloIframeContext, updatedBoardAndOrganizationMembers)
        members.current = updatedBoardAndOrganizationMembers
        setBoardAndOrganizationMembers(updatedBoardAndOrganizationMembers)
    }, [members, trelloIframeContext])

    /**
     * Search a member on the board members list and if it doesn't exists, search it on the API and store it to avoid repeated requests
     * @param memberId
     */
    const searchAndStoreMember = useCallback(async (memberId: string) => {
        if (!members.current || members.current.length === 0) return
        let boardMember = members.current.find((boardMember: Member) => boardMember.id === memberId)
        if (!boardMember) {
            boardMember = await getMemberById(licenseDetails, memberId)
            const updatedBoardAndOrganizationMembers = [...members.current, boardMember].reduce(uniqueMemberCalculator, [])
            await updateMembers(updatedBoardAndOrganizationMembers)
        }
        return boardMember
    }, [members, updateMembers, licenseDetails])


    /**
     * Scroll in the view to a comment that was stored in the local storage
     */
    const scrollToComment = () => {
        const commentId = localStorage.getItem('commentId') || ''
        const commentElement = document.getElementById(commentId)
        if (commentId != null && commentElement != null) {
            setCommentIdToScroll(commentId)
            commentElement.scrollIntoView()
        }
    }

    /**
     * Given the given comments, process them, store them on the state and update the iframe size to show all
     * @param comments object obtained from the response
     * @param members (optional) Member array to process comments. If it's not given, it will use the boardAndOrganizationMembers state
     */
    const processAndStoreComments = useCallback(async (comments: CommentsForCardResult) => {
        const proccessedComments = await processComments(licenseDetails, comments.data, searchAndStoreMember)
        setComments(proccessedComments)
        setTimeout(() => {
            sizeToContent(trelloIframeContext)
            scrollToComment()
        }, 200)
    }, [licenseDetails, trelloIframeContext, searchAndStoreMember])

    const getComments = useCallback(async () => {
        const comments = await getCommentsForCard(licenseDetails)
        await processAndStoreComments(comments)
    }, [licenseDetails, processAndStoreComments])

    /**
     * Clear local storage comment scrolled
     */
    function clearCommentScrolled() {
        if (localStorage.getItem('commentId')) {
            localStorage.removeItem('commentId')
        }
    }

    /**
     * Get organization and board members, comments from database, process and store all data
     */
    const getMembersAndComments = useCallback(async () => {
        const boardId = trelloIframeContext.getContext().board
        let boardAndOrganizationMembersFromAPI: Member[] = []
        if (!members.current || members.current.length === 0) {
            let [boardMembers, organizationMembers, comments] = await Promise.all([
                getMembershipsForBoard(licenseDetails, boardId),
                getOrganizationMembersOnCurrentBoard(licenseDetails, searchAndStoreMember),
                getCommentsForCard(licenseDetails)
            ])
            boardAndOrganizationMembersFromAPI = [...organizationMembers, ...boardMembers]
            const uniqueMembersToAdd = [...members.current, ...boardAndOrganizationMembersFromAPI].reduce(uniqueMemberCalculator, [])
            await updateMembers(uniqueMembersToAdd)

            await processAndStoreComments(comments)
            setIsLoading(false)
        } else await getComments()
        if (refreshTimeout) clearTimeout(refreshTimeout)
        setRefreshTimeout(setTimeout(() => {
            getMembersAndComments().then(() => clearCommentScrolled())
        }, 10_000))
    }, [licenseDetails, getComments, members, updateMembers, processAndStoreComments, trelloIframeContext, refreshTimeout, searchAndStoreMember])

    useEffect(() => {
            if (licenseDetails.isAuthorized && licenseDetails.isLicensed()) {
                getMembersAndComments()
                markCommentAsRead(trelloIframeContext)
            }

            if(!orahUser) {
                licenseDetails.getUser().then((user: any) => {
                    setOrahUser(user)
                    setNotLoggedInOrahUser(user === undefined)
                    if (!licenseDetails.isLicensed() && user) {
                        createEvalLicense(licenseDetails, false).then(async () => {
                            await licenseDetails.checkLicenseCapabilities()
                        })
                    }
                })
            }

        },  // eslint-disable-next-line
        [licenseDetails.isAuthorized, licenseDetails.isLicensed(), trelloIframeContext, orahUser, notLoggedInOrahUser])


    if (!trelloIframeContext) {
        return renderTrelloContextMissing()
    }

    if (!licenseDetails.isAuthorized) { //TODO restore when remove extend evals: || !licenseDetails.isLicensed()
        licenseDetails.smallErrorMessageWindow = true
        // if (!licenseDetails.isAuthorized) {
        return <UnauthorizedStep licenseDetails={licenseDetails}/>
        // }
        // return <UnpaidStep licenseDetails={licenseDetails}/>
    }

    if (!licenseDetails.isLicensed() && !orahUser && !notLoggedInOrahUser && isLoading) {
        return <Skeleton active={true}/>
    }

    if (!licenseDetails.isLicensed() && !orahUser && notLoggedInOrahUser) { //TODO restore when remove extend evals
        return <NotLoggedInOrahError licenseDetails={licenseDetails} setNotLoggedInOrahUser={setNotLoggedInOrahUser}/>
    }

    return (
        <div className={classes.appContainer} id="content">
            <SettingsMenuButton licenseDetails={licenseDetails}/>
            <GetStarted licenseDetails={licenseDetails}/>
            <CommentEditor licenseDetails={licenseDetails} getComments={getComments}
                           boardAndOrganizationMembers={boardAndOrganizationMembers} setComments={setComments}
                           comments={comments}
                           defaultText={'Write a comment...'} ref={commentEditorRef}
            />
            <CommentList licenseDetails={licenseDetails} comments={comments} getComments={getComments}
                         setComments={setComments}
                         boardAndOrganizationMembers={boardAndOrganizationMembers}
                         searchAndStoreMember={searchAndStoreMember} commentIdToScroll={commentIdToScroll}/>
        </div>)
}

export default ThreadedCommentsDisplay